const main_state = {
	login : false,
	loader: false,
	loader_hash: false,
	lang_code: 'EN',
	popup_open: true,
	popup_type: 'error',
	err_msg: 'not_logged_in',
}

const main = (state = main_state, action) => {
    switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data };
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data}
		case 'TOGGLE_LOADER_HASH':
      		return { ...state, loader_hash: action.data };
		case "TOGGLE_POPUP":
			return { ...state, popup_open: action.bool, popup_type: action.tipe}
		default:
			return state;
	}
};

export default main;
