import axios from "axios";
import { err_msg, getLsObject } from "helper";
// import { set_hash } from './auth';
// import { put_hash } from './hash';

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false
});

const common = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
	withCredentials: false
});

export const toggle_popup = (bool, tipe) => ({
	type: "TOGGLE_POPUP",
	bool,
	tipe
});

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data
});

export const toggle_loader_hash = (data) => ({
	type: "TOGGLE_LOADER_HASH",
	data: data
});

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data
});

const setLang = (lang_code) => {
	switch (lang_code) {
		case "ID":
			return "ID";
		case "SG":
		case "MY":
		case "PH":
			return "EN";
		case "TW":
		case "HK":
		case "MO":
			return "TW";
		case "TH":
			return "TH";
		default:
			return "EN";
	}
};

export const get_data = (lang_code) => {
	return (dispatch) => {
		dispatch(toggle_loader(true));
		cdn.get(
			`https://cdngarenanow-a.akamaihd.net/devid/tr_codm_delete_request/${setLang(lang_code)}.json?${Math.random() * 10000
			}`
		)
			.then((resp) => {
				dispatch(put_data("data", resp.data));
			})
			.catch((err) => { })
			.then(() => {
				dispatch(toggle_loader(false));
			});
	};
};

export const get_field = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true));
		axios
			.get(`/api/form_field/`)
			.then((resp) => {
				dispatch(put_data("field", resp?.data?.data?.fields));
			})
			.catch((err) => { })
			.then(() => {
				dispatch(toggle_loader(false));
			});
	};
};

export const check_login = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true));
		axios
			.get(`/oauth/check_login/`)
			.then((resp) => {
				dispatch(put_data("user", resp.data.user));
				dispatch(put_data("lang_code", resp.data.user.region));
				dispatch(toggle_popup(false, "error"));
				dispatch(get_info());
				dispatch(get_field());
			})
			.catch((err) => { })
			.then(() => {
				dispatch(toggle_loader(false));
			});
	};
};

export const get_info = () => {
	return (dispatch) => {
		axios
			.get(`/api/info/`)
			.then((resp) => {
				dispatch(put_data("info", resp.data.data));
			})
			.catch((err) => { });
	};
};

export const verify_request = (payload) => {
	return (dispatch) => {
		dispatch(toggle_loader(true));
		axios
			.post(`/api/verify/`, payload)
			.then((resp) => {
				dispatch(put_data("status", "deleted"));
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)));
			})
			.then(() => {
				dispatch(toggle_loader(false));
			});
	};
};

export const cancel_request = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true));
		axios
			.post(`/api/cancel/`)
			.then((resp) => {
				dispatch(put_data("status", "activated"));
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)));
			})
			.then(() => {
				dispatch(toggle_loader(false));
			});
	};
};

/**
 *
 * Example of how to call API
 *
 */
export const login = () => {
	return (dispatch, getState) => {
		// UNCOMMENT & ADD DEFAULT LANG AS PARAM ex: 'EN'
		// dispatch(get_translate('EN'))
		dispatch(toggle_loader(true));

		axios
			.get(process.env.REACT_APP_API_ENDPOINT)
			.then((resp) => {
				dispatch(put_data("login", resp.data));

				/*
					If you want to set the data as HASH, 
					replace the key below with a hash key and 
					don't forget to include the state key into 
					whitelist in reducers/index.js
				*/
				// const key = 'login';
				// const { temp_hash } = getState().hash;
				// if (temp_hash[key].type === 'set_hash') {
				//   dispatch(set_hash(key));
				// } else {
				//   dispatch(put_hash(key, temp_hash[key].data));
				// }
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)));
			})
			.then(() => {
				dispatch(toggle_loader(false));
			});
	};
};

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = (default_lang) => {
	return (dispatch) => {
		let language = getLsObject("param")?.lang.toUpperCase() || default_lang;

		const main_localize = cdn.get(`${language}.json?${Math.random() * 10000}`);
		const common_localize = common.get(`${language}.json?${Math.floor(Math.random() * 10000)}`);

		axios
			.all([main_localize, common_localize])
			.then((resp) => {
				const json = {
					...resp[0].data.data,
					...resp[1].data
				};
				dispatch(put_data("lang_json", json));
			})
			.catch((err) => { });
	};
};

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
	return (dispatch, getState) => {
		if (err_msg === "not_logged_in") {
			const { auth } = getState();
			window.location.href = `${`${window.location.origin}/?access_token=${auth.param.access_token}&account_id=${auth.param.account_id}&nickname=${auth.param.nickname}&region=${auth.param.region}`}`;
		} else {
			if (err_msg) {
				dispatch(put_data("err_msg", err_msg));
			}
			dispatch(toggle_popup(true, "error"));
			dispatch(toggle_loader(false));
		}

		if (err_msg === "invalid_region") {
			localStorage.clear();
		}
	};
};
