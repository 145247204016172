import React, { lazy, Suspense, useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ErrorBoundary from "ErrorBoundary";
import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import { componentLoader, getUrlParam } from "helper";

// REDUX
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "redux/actions/main";
import { check_login } from "redux/actions/main";

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")));

export const Main = (props) => {
	const { status, data } = useSelector((state) => state.main);
	const dispatch = useDispatch();

	useEffect(() => {
		const token = getUrlParam("token");
		if (token !== false) {
			window.localStorage.setItem("token", token);
			window.location.href = window.location.origin;
		} else {
			dispatch(check_login());
		}
	}, [dispatch, status]);

	return (
		<Router basename={'/'}>
			<div id="Main" className="main-panel">
				<ErrorBoundary>
					<Suspense fallback={<LoadingScreen />}>
						<ViewportRestrict display={true} type="portrait" text={data?.txt_landscape_only} />
						<Loader loader={props?.main?.loader} />
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={Home} />
								<Route component={NotFound} />
							</Switch>
						</div>
					</Suspense>
				</ErrorBoundary>
			</div>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	main: state.main
});

const mapDispatchToProps = (dispatch) => ({
	actionsMain: bindActionCreators(mainActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
